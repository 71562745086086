 /deep/ .el-button--primary {
  background-color: #2878ff;
  border-color: #2878ff;
}
 /deep/ .el-step__head.is-finish {
  color: #2878ff;
  border-color: #2878ff;
}
 /deep/ .el-step__title.is-finish {
  color: #2878ff;
}
.changeContainer {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
}
.changeContainer .change_main {
  display: flex;
  width: 100%;
  max-width: 1200px;
  margin: calc(62px + 2rem) auto 2rem;
  flex-grow: 1;
  flex-basis: 0;
}
.changeContainer .change_main .change_main_form {
  width: 500px;
  margin: auto;
  margin-top: 50px;
}
.changeContainer .change_main .change_main_form .el-form-item {
  text-align: left;
}
.changeContainer .change_main .change_main_form .success {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 40px;
}
.changeContainer .change_main .change_main_form .success div {
  color: #666666;
  text-align: center;
  font-size: 12px;
  margin-top: 20px;
}
.changeContainer .change_main .change_main_form .button-group .el-button {
  width: 250px;
}
